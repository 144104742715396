import React from 'react'
import {graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import theme from '../../../styles/theme'
import {Subtitle} from '../../../elements'
import {Layout, MetaData, BrowserWindow} from '../../../components'
import {
  ThemePageHeader,
  ThemeFeatureCard,
  FeatureCards,
  Features,
  FeaturesList,
  TwoColumnSection,
  Demos,
  DemoCard,
} from '../../../components/ThemePage'
import CTA, {CTAItem} from '../../../components/CTA'

import MembershipIcon from '../../../images/inline-svgs/online-payment.svg'
import GhostIcon from '../../../images/inline-svgs/ghost.svg'
import DocumentationIcon from '../../../images/inline-svgs/documents.svg'
import DownloadIcon from '../../../images/inline-svgs/download.svg'
import ResponsiveIcon from '../../../images/inline-svgs/responsive.svg'
import DarkModeIcon from '../../../images/inline-svgs/day-and-night.svg'
// import CustomizableIcon from '../../../images/inline-svgs/customizable.svg'
// import TranslateIcon from '../../../images/inline-svgs/translate.svg'
import customizationVideo from '../../../images/ampersand/ampersand-color-change.mp4'

const AmpersandGhostTheme = ({data}) => (
  <Layout>
    <MetaData data={data.currentPage} />
    <ThemePageHeader
      title="Ampersand"
      eyebrow="Ghost"
      description="Ampersand is a multipurpose <a href='https://ghost.org' class='link--hover--underline' target='_blank' rel='noopener noreferrer'>Ghost</a> theme. You can use it to build blogs, online membership-based publications, and lot more."
      price="$ 99"
      purchaseLink="https://gum.co/ampersand-ghost-theme"
      demoLink="https://ampersand.stylesheets.dev/"
      image={data.header.childImageSharp.gatsbyImageData}
      eyebrowLink="/themes/ghost/"
    />

    <FeatureCards>
      <ThemeFeatureCard
        title="Ghost 4.x"
        description="Supports latest version of Ghost."
      >
        <GhostIcon fill="#444" width="40px" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Memberships Support"
        description="Add Ghost memberships and subscription feature to your site."
      >
        <MembershipIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Reading Modes"
        description="The theme comes with Light &amp; Dark reading modes."
      >
        <DarkModeIcon fill="#444" />
      </ThemeFeatureCard>
      {/* <ThemeFeatureCard
        title="Easy to Customize"
        description="Fully customizable Fonts &amp; Colors with CSS variables and SMACSS file organization."
      >
        <CustomizableIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Fully Documented"
        description="Extensively documented for setting up and customizing your site."
      >
        <DocumentationIcon fill="#444" />
      </ThemeFeatureCard>
      <ThemeFeatureCard
        title="Translation Ready"
        description="Translation ready and includes English and German translations. More languages can be added."
      >
        <TranslateIcon fill="#444" />
      </ThemeFeatureCard> */}
    </FeatureCards>

    <Demos
      title="Demos"
      bg={theme.color.primary.base}
      color={theme.color.inverted}
    >
      <DemoCard
        title="Default"
        link="https://ampersand.stylesheets.dev"
        image={data.demoDefault}
        imageAlt="Default demo site"
        color={theme.color.inverted}
      />
      <DemoCard
        title="Header Type B"
        link="https://ampersand-header-b.stylesheets.dev/"
        image={data.demoHeader2}
        imageAlt="Demo with different header"
        color={theme.color.inverted}
      />
      <DemoCard
        title="Hero Full Width"
        link="https://ampersand-hero-full.stylesheets.dev"
        image={data.demoHero2}
        imageAlt="Demo with full width hero image"
        color={theme.color.inverted}
      />
      <DemoCard
        title="Extras"
        link="https://ampersand-demo-2.stylesheets.dev"
        image={data.demo2}
        imageAlt="Demo with a different color scheme"
        color={theme.color.inverted}
      />
    </Demos>

    <Features className="margin--large--top">
      <FeaturesList>
        <li>Supports latest versions of Ghost 4.0+</li>
        {/* <li>Responsive layout</li> */}
        {/* <li>Customizable typography and color palette</li> */}
        <li>Dark mode</li>
        {/* <li>Google fonts</li> */}
        <li>Ghost premium membership feature support</li>
        <li>Social media sharing icons</li>
        <li>Syntax highlighting with Prism.js</li>
        <li>
          Search (In-built services include Google Programmable Search Engine
          &amp; GhostHunter search)
        </li>
        <li>Space for integrating comments from services like Disqus etc.</li>
        <li>Posts &amp; Tags archive</li>
        <li>Translation ready</li>
        <li>Accessibility tested for WCAG Level AA compatibility</li>
        <li>Regular updated for latest Ghost version</li>
      </FeaturesList>
    </Features>

    <TwoColumnSection backgroundColor={theme.color.background.lightgrey}>
      <div>
        <h2>Customize Colors Directly from Your Ghost Dashboard</h2>
        <Subtitle margin="24px 0 0">
          Ampersand theme could be adapted easily to your brand identity with
          customizable fonts and color palette.
        </Subtitle>
      </div>
      <div
        style={{
          justifySelf: 'center',
        }}
      >
        <BrowserWindow video={customizationVideo} colorTheme="light" />
      </div>
    </TwoColumnSection>

    <TwoColumnSection reverse>
      <div>
        <StaticImage
          src="../../../images/ampersand/ampersand-multiple-heros.png"
          alt="Multiple hero layouts available"
          placeholder="blurred"
          width={700}
          layout="constrained"
        />
      </div>
      <div>
        <h2>Multiple Layout Options</h2>
        <Subtitle margin="24px 0 0">
          Ampersand theme comes with three different hero layouts – Default,
          Full-width Image &amp; No-Image.
        </Subtitle>
        <Subtitle margin="24px 0 0">
          Hero section is used on homepage to introduce the site and can be
          added without an coding by the user.
        </Subtitle>
      </div>
    </TwoColumnSection>

    <CTA title="Ready to Get Started?">
      <CTAItem
        title="Purchase Theme"
        link="https://gum.co/ampersand-ghost-theme"
        externalLink
      >
        <DownloadIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="View Demo"
        link="https://ampersand.stylesheets.dev/"
        externalLink
      >
        <ResponsiveIcon fill="#fff" />
      </CTAItem>
      <CTAItem
        title="Documentation"
        link="https://docs.stylesheets.dev/ampersand"
        externalLink
      >
        <DocumentationIcon fill="#fff" />
      </CTAItem>
    </CTA>
  </Layout>
)

export default AmpersandGhostTheme

export const query = graphql`
  query {
    currentPage: ghostPage(slug: {eq: "ampersand-ghost"}) {
      ...GhostMetaPageFields
    }

    header: file(relativePath: {eq: "themes/ampersand.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    demoDefault: file(
      relativePath: {eq: "ampersand/demos/ampersand-default.png"}
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 640)
      }
    }
    demoHero2: file(relativePath: {eq: "ampersand/demos/ampersand-hero2.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 640)
      }
    }
    demoHeader2: file(
      relativePath: {eq: "ampersand/demos/ampersand-header2.png"}
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 640)
      }
    }
    demo2: file(relativePath: {eq: "ampersand/demos/ampersand-demo2.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 640)
      }
    }
  }
`
